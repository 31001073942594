#loadingScreen{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100dvh;
}


#loadingScreen_LogoContainer{
    width: max(250px,20%);
    aspect-ratio: 1/1;
    animation-name: fluct;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    opacity: 0.6;
}




@keyframes fluct{
    from{ scale: 1;}
    50% {
        scale: 1.1;
        opacity: 1;
    }
    to{ scale: 1.0;}
}